.react-terminal-wrapper {
  background-color: rgba(37, 42, 51, 0.95) !important;;
  font-size: 12pt !important;;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px !important;
  padding: 50px 15px !important;
  overflow-x: hidden  !important;
  z-index: 1;
  .react-terminal-light {
    background: #fff;
    color: #1a1e24;
    box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.1);
    &:after {
        color: black;
    }
}
  .react-terminal-line {
    font-size: 9pt;
  }
  .react-terminal-input[data-terminal-prompt]:before {
    color: #0acb0a;
    padding-top: 5px;
  }

  .react-terminal-window-buttons {
    gap: 10;
    button {
      height: 10px;
      width: 10px;
    }
  }
  .react-terminal {
    height: 400px !important;
    overflow-x: hidden;
    word-wrap: break-word;
  }
}
