section.notebook-container {
  padding-top: 100px;
  padding-bottom: 50px;
  @media (max-width: 468px) {
    display: none;
  }
  .notebook {
    background: url("images/bg_macos_bigsur.jpg") no-repeat white fixed;
    background-size: cover;
    height: 600px;
    border: 20px solid #282828;
    border-radius: 15px 15px 0 0;
    margin: 0 5%;
    overflow: hidden;
    .screen {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
      position: relative;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 0;
      .terminal-container {
        cursor: pointer;
        min-width: 605px;
      }
      .icon-terminal {
        display: flex;
        background-color: black;
        
        border-radius: 5px;
        bottom: 5px;
        color: rgba(17, 216, 17, 0.736);
        cursor: pointer;
        font-size: 10pt;
        left: 5px;
        height: 28px;
        padding: 5px 8px;
        position: absolute;
        z-index: -1;
      }
      .icon-terminal.active {
        background-color: rgba(0, 0, 0, 0.6);
      }
      svg {
        flex: 1;
      }
      p.text-icon-terminal {
        color: rgba(17, 216, 17, 0.736);
        flex: 1;
        font-size: 10pt;
        line-height: 15px;
        padding-left: 10px;
      }
      .icon-email {
        display: flex;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 5px;
        bottom: 35px;
        color: white;
        cursor: pointer;
        font-size: 12pt;
        left: 5px;
        height: 28px;
        padding: 5px 7px 4px 8px;
        position: absolute;
        z-index: -1;
        &:hover {
          background-color: black;
        }
      }
      .icon-email.active {
        background-color: white;
        color: black;
      }
      .email-container {
        cursor: pointer;
        min-height: 400px;
        min-width: 400px;
      }
    }
  }
  .notebook-footer {
    border-radius: 5px 5px 5px 5px;
    height: 45px;
    background: #a3a3a3;
    .rectangle {
      background-color: #ccc;
      width: 150px;
      height: 20px;
      border-radius: 0 0 10px 10px;
      margin: 0 auto;
      box-shadow: inset 0px -6px 10px 3px rgba(0, 0, 0, 0.5);
    }
  }
}
/* Modify styles for dark mode */
.dark-theme {
  section.notebook-container {
    .notebook-footer {
      background: #353535;
      .rectangle {
        background-color: #4e4e4e;
      }
    }
  }
}
