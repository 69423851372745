@import url("https://fonts.googleapis.com/css2?family=Share+Tech&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
html,
body {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
  position: relative;
  // overflow-x: none;
  overflow-anchor: none;
}

.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  .modal-content {
    box-shadow: none;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    min-width: 300px;
    width: 50%;
    span.close {
      border-radius: 50%;
      background-color: black;
      color: white;
      cursor: pointer;
      font-family: "Ubuntu", sans-serif;
      font-weight: 400;
      font-size: 16pt;
      height: 25px;
      line-height: 22px;
      position: absolute;
      right: -10px;
      text-align: center;
      top: -13px;
      width: 25px;
    }
  }
}

.corner {
  display: block;
  cursor: pointer;
  position: absolute;
  height: 60px;
  right: 0;
  top: 0;
  width: 60px;
}

body {
  .dark-theme {
    background-color: black;
  }
}

.theme-changer {
  background-color: #000000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid black;
  color: white;
  cursor: pointer;
  padding: 2px 8px;
  position: fixed;
  bottom: 8px;
  right: 8px;
  &:hover {
    background-color: transparent;
    border: 2px solid black;
    span,
    svg {
      color: black;
    }
  }
  span {
    color: white;
    font-size: 9pt;
    text-transform: uppercase;
    padding-right: 5px;
  }
  svg {
    color: white;
    font-size: 11pt;
  }
}

section.home {
  // background: linear-gradient(-150deg, #000000 2.4em, white 0) no-repeat;
  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: -11px;
  //   right: 36px;
  //   display: inline-block;
  //   height: 5em;
  //   width: 2.6em;
  //   transform: translate(1.1em, -0.5em) rotate(327deg);
  //   background: linear-gradient(
  //     to left bottom,
  //     transparent 50%,
  //     #c5c5c5 20px,
  //     #888888 27px,
  //     #d3d3d375
  //   );
  //   box-shadow: 0 10px 5px -4px #00000038;
  // }
  @media (max-width: 468px) {
    width: 100% !important;
  }
  h1 {
    // color: black;
    font-family: "Share Tech", Verdana, sans-serif;
    font-size: 180pt;
    line-height: 252px;
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 1250px) {
      font-size: 120pt;
      line-height: 200px;
    }
    @media (max-width: 960px) {
      font-size: 90pt;
      line-height: 130px;
    }
    @media (max-width: 720px) {
      font-size: 60pt;
      line-height: 86px;
    }
    @media (max-width: 450px) {
      font-size: 46pt;
      line-height: 48px;
    }
  }
  p.personal-description {
    font-family: "Ubuntu", sans-serif;
    font-weight: 300;
    font-size: 22pt;
    line-height: 25px;
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    span {
      font-weight: 900;
    }
    @media (max-width: 450px) {
      font-size: 16pt;
      line-height: 48px;
    }
  }
}

.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

footer {
  color: white;
  padding: 10px 0;
  text-align: center;
  .line-divider {
    background-color: rgba(0, 0, 0, 0.04);
    height: 1px;
    margin-bottom: 15px;
    width: 100%;
  }
  p {
    font-size: 10pt;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  svg {
    color: black;
    font-size: 16pt;
  }
}

.dark-theme {
  .theme-changer {
    background-color: white;
    border: 2px solid transparent;

    span {
      color: black;
    }
    svg {
      color: #fff017;
    }
    &:hover {
      background-color: #fff017;
      svg {
        color: black;
      }
    }
  }
  section.home {
    // background: linear-gradient(-150deg, white 2.4em, black 0) no-repeat;
    // &:after {
    //   background: linear-gradient(
    //     to left bottom,
    //     transparent 50%,
    //     #c5c5c5 20px,
    //     #888888 27px,
    //     #d3d3d375
    //   );
    // }
    .center-flex {
      h1 {
        color: white;
      }
    }
  }
  section {
    background-color: black;
    color: white;
  }

  footer {
   
    .line-divider {
      background-color: rgba(255, 255, 255, 0.1);
      height: 1px;
      width: 100%;
    }
    svg {
      color: white;
    }
  }
}
