.email-wrapper {
  background-color: white;
  border-radius: 16px;
  padding: 0;

  .email-header {
    background-color: white;
    border-radius: 15px 15px 0 0;
    clear: both;
    margin: 0;
    padding: 10px 0 0 10px;
    .circle {
      border-radius: 50%;
      float: left;
      height: 10px;
      margin-right: 5px;
      width: 10px;
    }
    .circle.red {
      background-color: #fe5f57;
    }
    .circle.yellow {
      background-color: #febc2e;
    }
    .circle.green {
      background-color: #26c73f;
    }
    .email-title {
      color: rgb(40, 40, 40);
      font-family: "Fira Mono", Consolas, Menlo, Monaco, "Courier New", Courier, monospace;
      font-size: 14px;
      padding-right: 50px;
      text-align: center;
    }
  }
  form {
    background-color: white;
    border-radius: 0 0 16px 16px;
    padding: 0 10px 10px 10px;
    .field {
      border-bottom: 1px solid #efefef;
      padding: 5px 0;
      label {
        border: 0;
        color: rgb(85, 85, 85);
        font-size: 8pt;
        margin-right: 10px;
      }
      input {
        border: 0 !important;
        color: rgb(40, 40, 40);
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        font-size: 12px;
        width: 300px;
        &:focus {
          border: 0 !important;
          outline: none !important;
        }
      }
      .email-to {
        background-color: #efefef;
        border-radius: 8px;
        color: rgb(40, 40, 40);
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        font-size: 12px;
        padding: 5px 10px;
        margin: 0;
      }
    }
    .body {
      margin-top: 5px;
      .quill {
        padding-bottom: 10px;

        .ql-toolbar.ql-snow {
          display: none;
          border-top: 0 !important;
          border-right: 0 !important;
          border-left: 0 !important;
          border-bottom: 1px solid #efefef;
          padding: 0;
        }
        .ql-container.ql-snow {
          border: 0;
          .ql-editor {
            color: rgb(40, 40, 40);
            font-family: "Ubuntu", sans-serif;
            font-weight: 400;
            font-size: 10pt;
            height: 200px;
            padding: 10px 0;
          }
        }
      }
    }
    input[type="submit"],
    button[type="submit"] {
      border: 0;
      border-radius: 10px;
      background-color: rgb(0, 22, 192);
      color: white;
      font-size: 9pt;
      padding: 5px 15px;
      &:hover {
        background-color: rgb(9, 33, 211);
      }
    }
  }
  .email-sent {
    background-color: white;
    border-radius: 0 0 16px 16px;
    color: black;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-size: 10pt;
    height: 200px;
    padding: 10px;
  }
}

.maximized {
  z-index: 1000;
  input[type="submit"],
  button[type="submit"] {
    bottom: 15px;
    position: absolute;
  }
}

.closed,
.minimized {
  display: none;
}
