section.working-profile {
  $height: 690px;
  .column-left {
    background-color: #252a33;
    border-radius: 15px 0 0 15px;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
    max-height: $height;
    .code-header {
      background-color: #202020;
      border-bottom: 1px solid #494949;
      border-radius: 15px 0 0 0;
      clear: both;
      margin: 0 -12px;
      padding: 10px 0 15px 10px;
      .circle {
        border-radius: 50%;
        float: left;
        height: 10px;
        margin-right: 5px;
        width: 10px;
      }
      .circle.red {
        background-color: #fe5f57;
      }
      .circle.yellow {
        background-color: #febc2e;
      }
      .circle.green {
        background-color: #26c73f;
      }
    }
    .code-content {
      margin: 0;
      padding: 0;
      pre {
        background: #252a33 !important;
        font-size: 8pt !important;
      }
      code {
        color: white;
        font-size: 8pt !important;
      }
    }
  }
  .column-right {
    // background-color: white;
    border-radius: 0 15px 15px 0;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
    max-height: $height;
    overflow-y: scroll;
    @media (max-width: 768px) {
      border-radius: 10px;
      max-height: none;
    }
    .content-result {
      padding: 15px;
      p.personal-description {
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        font-size: 10pt;
        font-style: normal;
        line-height: 22px;
        text-align: justify;
      }

      .line-divider {
        background-color: #e2e2e2;
        height: 1px;
        margin-bottom: 20px;
        width: 100%;
      }

      ul {
        margin: 0;
        padding: 0;
        li {
          display: flex;
          flex-direction: row;
          list-style: none;
          margin-bottom: 30px;
          &:hover {
            .company-logo,
            .company-logo img {
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border-radius: 50%;
            }
          }
          .company-logo {
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
            height: 60px;
            transition: border-radius 0.5s ease;
            width: 60px;
            img {
              border-radius: 10px;
              transition: border-radius 0.5s ease;
              width: 60px;
            }
          }
          .company-description {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-left: 10px;
            vertical-align: top;
            h3.company-name {
              flex: 1;
              font-family: "Ubuntu", sans-serif;
              font-weight: 600;
              font-size: 11pt;
              font-style: normal;
              margin: 0;
              padding-bottom: 8px;
              text-transform: uppercase;
            }
            p.company-details {
              flex: 1;
              font-family: "Ubuntu", sans-serif;
              font-weight: 400;
              font-size: 9pt;
              font-style: normal;
              line-height: 22px;
              margin: 0;
              padding-bottom: 5px;
              text-align: justify;
            }
            h4.company-rol {
              flex: 1;
              font-family: "Ubuntu", sans-serif;
              font-weight: 300;
              font-size: 8pt;
              font-style: normal;
            }
            .dates-of-work {
              flex: 1;
              font-family: "Ubuntu", sans-serif;
              font-weight: 300;
              font-size: 8pt;
              font-style: italic;
              padding-bottom: 5px;
            }
            p.company-development {
              flex: 1;
              font-family: "Ubuntu", sans-serif;
              font-weight: 300;
              font-size: 9pt;
              font-style: normal;
              line-height: 22px;
              text-align: justify;
              a {
                color: black;
                font-weight: bold;
                padding: 2px;
                text-decoration-style: dotted;
                &:hover {
                  text-decoration-style: unset;
                }
              }
            }
            label {
              font-family: "Ubuntu", sans-serif;
              font-weight: 500;
              font-size: 8pt;
              font-style: normal;
              line-height: 22px;
              text-transform: uppercase;
            }
            .tags {
              flex: 1;
              margin: 0;
              padding: 0;
              span {
                background-color: #f9f9f9;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 2px;
                display: inline-flex;
                flex-direction: row;
                font-family: "Ubuntu", sans-serif;
                font-weight: 300;
                font-size: 7pt;
                font-style: normal;
                padding: 2px 5px;
                margin: 0 5px 0 0;
              }
            }
          }
        }
      }
    }
  }
}

.dark-theme {
  section.working-profile {
    .column-right {
      background-color: #2e2e2e;
      p.company-development {
        a {
          color: white !important;
        }
      }
      .tags span {
        background-color: #484848 !important;
      }
    }
  }
}
