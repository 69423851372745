section.quote {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  // min-height: 600px;
  p.text {
    font-family: "Ubuntu", sans-serif;
    font-weight: 900;
    font-size: 42pt;
    font-style: normal;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 28pt;
    }
  }
}
